import { Component, OnInit } from '@angular/core';
import {CoursesService} from '../../services/courses.service';
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {ModeService} from "../../services/mode.service";
import {CourseCategoryService} from "../../services/course-category.service";
import {CampusService} from "../../services/campus.service";

@Component({
  selector: 'app-filters2',
  templateUrl: './filters2.component.html',
  styleUrls: ['./filters2.component.scss']
})
export class Filters2Component implements OnInit {

  category = '';
  campus = '';
  formula = '';
  location = '';
  searchIcon = faSearch;
  selectIcon = faChevronDown;
  campusList = [];

  constructor(private campusService: CampusService, private courseService: CoursesService, public modeService: ModeService, public courseCategoryService: CourseCategoryService) {
  }

  ngOnInit(): void {
    this.campusService.campus$.subscribe(data => this.campusList = data.map(c => c.name.toLowerCase()));
  }

  submit() {
    const filters = {
      category: this.category !== '' ? this.category : null,
      formula: this.formula !== '' ? this.formula : null,
      location: this.location !== '' ? this.location : null,
      campus: this.campus !== '' ? this.campus : null,
    };
    this.courseService.applyFilters(filters);
  }
}
