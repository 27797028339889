<nav class="navbar navbar-expand-lg navbar-light p-md-5 mb-5">
  <a class="navbar-brand" href="#"><img src="../../../../assets/SSM-rome-logo.png" class="img-fluid"></a>
</nav>

<div class="my-md-5 pt-md-5 p-4 container">
  <div class="row no-gutters">
    <div class="col-sm-8 mb-3 pr-sm-4">
      <h1 class="text-blue font-weight-bold mb-4">Thank You for Requesting Information.</h1>
      <h4 class="text-black-50 pr-sm-4">
        Thank you for your interest in our courses, we will call you soon.<br>
        In the meanwhile, click on the button below to find out more about our courses.
      </h4>
      <div class="py-3 text-center text-sm-left">
        <a class="btn btn-swiss btn-lg text-white font-weight-bold" href="https://ssm.swiss/academics/">DISCOVER MORE</a>
      </div>
    </div>
    <div class="col-sm-4">
      <img src="../../../../assets/colosseum.png" class="img-fluid" style="height: 250px">
    </div>
  </div>
</div>


<app-footer></app-footer>
