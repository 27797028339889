import {CoursesService} from '../../../services/courses.service';

;import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  category = '';
  formula = '';
  location = '';

  constructor(private courseService: CoursesService) { }

  ngOnInit(): void {
  }

  submit() {
    const filters = {
      category: this.category !== '' ? this.category : null,
      formula: this.formula !== '' ? this.formula : null,
      location: this.location !== '' ? this.location : null
    };
    this.courseService.applyFilters(filters);
  }
}
