<div class="pt-4 pb-3" id="footer">
  <div class="bg-white py-2">
    <div class="row justify-content-center">
      <div class="col-6 col-sm-3 col-md-1">
        <img class="img-fluid" style="max-height: 100px" src="assets/accreditations/DEAC-Thumbnail-2.png">
      </div>
      <div class="col-6 col-sm-3 col-md-1">
        <img class="img-fluid" style="max-height: 100px" src="assets/accreditations/onu.jpeg">
      </div>
      <div class="col-6 col-sm-3 col-md-1">
        <img class="img-fluid" style="max-height: 100px" src="assets/accreditations/SSM-Rome-USR-Lazio-600x403.jpeg">
      </div>
      <div class="col-6 col-sm-3 col-md-1">
        <img class="img-fluid" style="max-height: 100px" src="assets/accreditations/SSM-swiss-chea-600x403.jpeg">
      </div>
      <div class="col-6 col-sm-3 col-md-1">
        <img class="img-fluid" style="max-height: 100px" src="assets/accreditations/SSM-swiss-eduqua-accreditation-600x403.jpeg">
      </div>
      <div class="col-6 col-sm-3 col-md-1">
        <img class="img-fluid" style="max-height: 100px" src="assets/accreditations/SSM-swiss-iacbe-accreditation-600x403.jpeg">
      </div>
      <div class="col-6 col-sm-3 col-md-1">
        <img class="img-fluid" style="max-height: 100px" src="assets/accreditations/Webp.net-resizeimage-5-600x403.jpg">
      </div>
      <div class="col-6 col-sm-3 col-md-1">
        <img class="img-fluid" style="max-height: 100px" src="assets/accreditations/Webp.net-resizeimage-35-600x403.png">
      </div>
    </div>
  </div>
  <div class="py-5 border-bottom">
    <div class="row no-gutters text-white justify-content-center">
      <div class="col-sm-3 px-3 px-md-5 mb-3">
        <h5 class="font-weight-bold">CONTACTS</h5>
        <p class="text-white-50">
          Swiss School of Management<br>
          Piazza del Biscione 95<br>
          00186 Roma, Italia<br>
          +39 351 867 3611<br>
          info@ssm.swiss<br>
        </p>
      </div>
      <div class="col-sm-3 px-3 px-md-5 mb-3">
        <h5 class="font-weight-bold">ACCREDITATIONS</h5>
        <p class="text-white-50">
          All programs run at Swiss School of Management are fully accredited and quality certified.
        </p>
      </div>

      <div class="col-sm-3 px-3 px-md-5 text-white-50 mb-3">
        <h5 class="font-weight-bold text-white">ABOUT SSM</h5>
        <div>
          <a target="_blank" href="https://ssm.swiss/discover-ssm/">Discover SSM</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/presidents-welcome/">President’s welcome</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/our-campus/">Our Campus</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/student-referrals/">Student referrals</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/entry-visa-stay/">Entry visa & stay</a>
        </div>
      </div>

      <div class="col-sm-3 px-3 px-md-5 text-white-50 mb-3">
        <h5 class="font-weight-bold text-white">ACADEMICS</h5>
        <div>
          <a target="_blank" href="https://ssm.swiss/bachelor-of-business-administration/">Bachelor Studies</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/mba-degree-programs/">MBA Programs</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/doctorate-programs/">Doctorate Programs</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/leadership-program/">Leadership Programs</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/criminology/">Criminology</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/professional-courses/">Professional Courses</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/luxury-management/">Luxury Management</a>
        </div>
        <div>
          <a target="_blank" href="https://ssm.swiss/master-of-research-mres/">Master Research</a>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-3 text-center text-white-50">
    <p>© {{year}} Swiss School of Management. <a href="https://www.iubenda.com/privacy-policy/13761172" target="_blank">Privacy Policy</a> - <a target="_blank" href="https://www.iubenda.com/privacy-policy/13761172/cookie-policy/">Cookie Policy</a></p>
    <div class="px-2 px-sm-5">
      <div class="row no-gutters justify-content-center">
        <div class="col-sm-4 col-md-3">
          <div class="row no-gutters justify-content-center">
            <div class="col-2 text-center">
              <a href="https://twitter.com/ssmrome" target="_blank"><fa-icon [icon]="twitterIcon"></fa-icon></a>
            </div>
            <div class="col-2 text-center">
              <a href="https://www.facebook.com/swisschoolofmanagement/" target="_blank"><fa-icon [icon]="facebookIcon"></fa-icon></a>
            </div>
            <div class="col-2 text-center">
              <a href="https://www.linkedin.com/school/swiss-school-of-management/" target="_blank"><fa-icon [icon]="linkedinIcon"></fa-icon></a>
            </div>
            <div class="col-2 text-center">
              <a href="https://www.instagram.com/ssmrome/" target="_blank"><fa-icon [icon]="instagramIcon"></fa-icon></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
