import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit {
  selectedCourse;

  constructor(private modal: NgbModal) { }

  ngOnInit(): void {
    const form = document.getElementById('right-content');
    window.onscroll = (ev) => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 450) {
        form.style.opacity = '0';
        form.style.display = 'none';
      } else {
        form.style.display = 'block';
        form.style.opacity = '1';
      }
    };
  }

  openModal(content) {
    this.modal.open(content, {size: 'lg', centered: true});
  }

  updateForm(data) {
    console.log(data);
    this.selectedCourse = data;
  }
}
