import { Component, OnInit } from '@angular/core';
import {faInstagram, faLinkedin, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faFacebook} from '@fortawesome/fontawesome-free-brands';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  twitterIcon = faTwitter;
  facebookIcon = faFacebook;
  linkedinIcon = faLinkedin;
  instagramIcon = faInstagram;
  year;
  constructor() { }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

}
