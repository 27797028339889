<div class="row no-gutters my-3 bg-light px-2 p-2 border">
  <div class="col-md-3">
    <div class="form-group mb-0 p-2">
      <select class="form-control" [(ngModel)]="category">
        <option value="" selected disabled>Select program</option>
        <option value="">All courses</option>
        <option value="mba">MBA - Master in Business & Administration</option>
        <option value="mba-global-management-leadership">MBA - Global Management & Leadership</option>
        <option value="mba-finance">MBA - Finance</option>
        <option value="mba-entrepreneurship">MBA - Entrepreneurship</option>
        <option value="mba-human-resources">MBA - Human Resources</option>
        <option value="mba-fintech">MBA - Financial Technology</option>
        <option value="mba-health-safety-environment">MBA - Health, Safety and Environment</option>
        <option value="mba-luxury-management">MBA - Luxury Management</option>
        <option value="mba-marketing">MBA - Marketing</option>
        <option value="mba-no-profit-organization">MBA - Non for Profit Organizations</option>
        <option value="mba-project-management">MBA - Project Management</option>
        <option value="mba-logistics">MBA - Logistics Management</option>
        <option value="bba">BBA - Bachelor Degree in Business & Administration</option>
      </select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group mb-0 p-2">
      <select id="formula" class="form-control" [(ngModel)]="formula">
        <option value="" disabled selected>Select formula</option>
        <option value="">All formulas</option>
        <option value="full-time">Full time</option>
        <option value="part-time">Part time</option>
      </select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group mb-0 p-2">
      <select id="location" class="form-control" [(ngModel)]="location">
        <option value="" disabled selected>Select mode</option>
        <option value="">All modes</option>
        <option value="rome">Rome campus</option>
        <option value="online">Online</option>
      </select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group mb-0 p-2">
      <button class="btn-swiss text-white btn" style="width: 100%" (click)="submit()">Search</button>
    </div>
  </div>
</div>
