<ng-container *ngIf="courseService.results$ | async; let courses">

  <ng-container *ngIf="courses.length > 0; else noResults">
    <div *ngFor="let c of courses; let i = index" class="course-item pb-4 bg-white border-bottom border-left border-right mb-4">
      <div class="separator"></div>
      <div class="p-3 position-relative">
        <h4 class="text-blue font-weight-bold mb-5 pr-5 d-none d-md-block" style="cursor: pointer" (click)="selectedCourse = c;handleScroll(courses.length - i); selectCourse()">
          {{c.title}}
        </h4>
        <h4 class="text-blue font-weight-bold mb-5 pr-5 d-block d-md-none" (click)="selectedCourse = c; selectCourse(); openModal(form)">
          {{c.title}}
        </h4>
        <div class="text-right mb-sm-3">
          <img class="img-fluid flag" [src]="getFlag(c.language)">
        </div>
        <div class="text-center mb-3 text-blue" style="cursor: pointer" (click)="c.expand ? c.expand = false : c.expand = !c.expand">
          {{c.expand ? 'Hide' : 'View'}} details<br><fa-icon [icon]="c.expand ? compressIcon : expandIcon" size="2x"></fa-icon>
        </div>
        <p class="text-black-50 mb-5 none" [ngClass]="c.expand ? 'd-block' : 'd-none'" [innerHTML]="c.description">
        </p>

        <div class="text-center text-sm-left d-inline-flex" style="width: fit-content">
          <div class="float-left text-center mr-4" style="width: fit-content">
            <img [src]="getLocationIcon(c.location)" class="icon mb-2">
            <div class="text-blue small">
              {{c.location | uppercase}}
            </div>
          </div>
          <div class="float-left text-center mr-4" style="width: fit-content">
            <img src="../../../../assets/icon-calendar.svg" class="icon mb-2">
            <div class="text-blue small">
              {{(c.date.setMonth(c.date.getMonth()+1) === 7 ? c.date.setMonth(c.date.getMonth()+2) : c.date) | date:'MMMM' | uppercase}}
            </div>
          </div>
          <div class="float-left text-center mr-4" style="width: fit-content">
            <img src="../../../../assets/icon-time.svg" class="icon mb-2">
            <div class="text-blue small">
              {{c.formula | uppercase}}
            </div>
          </div>
          <div class="float-left text-center" style="width: fit-content">
            <img src="../../../../assets/card.svg" class="icon mb-2 p-1">
            <div class="text-blue small">
              PAY FLEXIBLY
            </div>
          </div>
        </div>
        <div class="d-none d-md-block float-right pt-3">
          <button class="btn btn-outline-swiss" (click)="selectedCourse = c;handleScroll(courses.length - i); selectCourse()">MORE INFORMATION</button>
        </div>
        <div class="clearfix"></div>
        <div class="d-block d-md-none text-center mt-4">
          <button class="btn btn-outline-swiss" (click)="selectedCourse = c; selectCourse(); openModal(form)">MORE INFORMATION</button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #noResults>
    <div class="py-5 text-center">
      No course match these filters.
    </div>
  </ng-template>

</ng-container>


<ng-template #form>
  <app-contact-form></app-contact-form>
</ng-template>

