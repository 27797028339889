import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CampusService } from 'src/app/services/campus.service';

@Component({
  selector: 'app-campus-manager',
  templateUrl: './campus-manager.component.html',
  styleUrls: ['./campus-manager.component.scss']
})
export class CampusManagerComponent implements OnInit {
  @Output() selected = new EventEmitter<any[]>();
  @Input() selectedCampus: any[];
  items: undefined|any[];

  selectedItems: any[] = [];

  toggleSelection(item: any) {
    item.checked = !item.checked;
  }

  getSelectedItems() {
    this.selectedItems = this.items.filter(item => item.checked);
    console.log(this.selectedItems);
  }

  constructor(private campusService: CampusService) {
  }

  emit() {
    this.selectedItems = this.items.filter(item => item.checked);
    this.selected.emit(this.selectedItems);
  }

  ngOnInit(): void {
    this.campusService.campus$.subscribe(data => {
      this.items = data.map(obj => {
        const newObj = {};
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            newObj[key] = typeof obj[key] === 'string' ? obj[key].toLowerCase() : obj[key];
          }
        }
        return newObj;
      });
      
      for (let key in this.selectedCampus) {
        if (this.selectedCampus.hasOwnProperty(key)) {
          if(this.selectedCampus[key]) {
            const objToUpdate = this.items.find(obj => obj.name === key);
            if (objToUpdate) {              
              // Update the name property
              objToUpdate.checked = true;
            }
          }
        }
      }      
    })
  }

}
