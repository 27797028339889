<div class="container">
  <div class="row justify-content-center my-5 p-3">
    <div class="col-md-8 border shadow bg-white">
      <div class="row no-gutters">
        <div class="col-md-4 col-12 p-4 p-md-5 text-center">
          <img class="img img-fluid mb-4" id="logo" src="../../../assets/SSM-rome-round-logo.png">
          <h4 class="text-blue font-weight-bold">Swiss School of Management</h4>
        </div>
        <div class="col-12 col-md-8 p-4 resign-bg-light-transparent">
          <h5>Accedi all'area personale</h5>
          <div *ngIf="getError()"
               class="alert alert-danger">
            <div *ngIf="getError() === 'auth/wrong-password'; else genericError">
              Combinazione email password sbagliata.
            </div>
            <ng-template #genericError>
              <div>
                Qualcosa è andato storto, riprova piu tardi.
              </div>
            </ng-template>
          </div>
          <form (submit)="handleLogIn()" #form="ngForm">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                [(ngModel)]="email"
                type="email"
                class="form-control"
                id="email"
                name="email"
                required
                #emailInput="ngModel"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$"
              >
              <div *ngIf="emailInput.invalid && emailInput.touched"
                   class="alert alert-danger">
                <div *ngIf="emailInput?.errors.required">
                  Campo email obbligatorio.
                </div>
                <div *ngIf="emailInput?.errors.pattern">
                  Inseire un indirizzo email valido.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input
                [(ngModel)]="password"
                type="password"
                class="form-control"
                id="password"
                name="password"
                minlength="6"
                #passwordInput="ngModel"
                required
              >
              <div *ngIf="passwordInput.invalid && passwordInput.touched"
                   class="alert alert-danger">
                <div *ngIf="passwordInput?.errors.required">
                  Campo password obbligatorio.
                </div>
                <div *ngIf="passwordInput?.errors.minlength">
                  La password è di almeno 8 caratteri.
                </div>
              </div>
            </div>
            <button [disabled]="form.form.invalid" type="submit" class="btn btn-outline-dark">Log in</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
