import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CourseManagerService {

  constructor(private db: AngularFirestore) { }

  createNew(data) {
    return this.db.collection('courses').add(data);
  }

  getToPromise(id) {
    return this.db.collection('courses').doc(id).get().toPromise();
  }

  update(data, id) {
    return this.db.collection('courses').doc(id).set(data, {merge: true});
  }

  remove(id) {
    return this.db.collection('courses').doc(id).delete();
  }

  getAll() {
    return this.db.collection('courses').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }
}
