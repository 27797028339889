<div class="bg-blue position-relative">
    <div id="main" class="bg-white">
        <header [style.background-image]="'url(assets/NewLanding/ssm.webp)'" id="header" style="min-height: 90vh; background-position: bottom right; background-size: cover; padding: 10px 10px" class="p-lg-5 p-3; position-relative">
<!--            <div style="height: 100vh; width: 100vw; position: absolute; background: rgba(0,0,0,0.3); top: 0; left: 0;"></div>-->
            <div class="pb-5 pt-md-0" style="min-height: 90vh; height: fit-content">
                <div>
                    <img alt="Swiss School of Management" id="logo" src="assets/NewLanding/SSM®_Logo_2020_CMYK_Tavola%20disegno%201%20copia.svg" class="img-fluid mb-4 mt-2" style="height: 100px; z-index: 99999" >

                    <h2 class="position-relative text-white mt-md-4" style="font-size: 56px; z-index: 10">We Create <span>Leaders</span></h2>

                    <div id="blu-box" class="p-3 text-white position-relative">
                        <h1 style="font-size: 24px; z-index: 10000">Swiss School of Management’s Programs</h1>
                        <p style="font-size: 16px; z-index: 12211">
                            We're the first European business school to be accredited by the DEAC and the only one dedicated to investing in our students' growth, not just as leaders in the business world, but as empowered individuals in all aspects of life.
                        </p>
                    </div>

                    <div class="my-4 text-center text-sm-left">
                        <!-- <button (click)="openModal(genericForm)" class="btn text-white px-5 mb-2 font-weight-bold btn-landing">
                            Request info
                        </button>
                        <span class="mx-3 text-white d-none d-sm-inline font-weight-bold">or</span>
                        <div class="my-1 text-white d-block d-sm-none font-weight-bold">or</div> -->
                        <button (click)="openModal(calendly)" class="btn text-white px-5 mb-2 font-weight-bold btn-landing">
                            Book a call
                        </button>
                    </div>

                    <p class="text-white mt-sm-3 mt-2" style="font-size: 16px;">
                        All programs run at Swiss School of Management are fully accredited and quality certified.<br>
                    </p>
                </div>
            </div>
            <div class="position-absolute text-center text-white pb-sm-3" style="width: 100%; height: fit-content; bottom: 0; left: 0">
                <div>
                    <p class="d-none d-md-block">
                        Choose your favorite program below.
                    </p>
                    <div class="bounce">
                        <fa-icon (click)="scrollDown()"  style="cursor:pointer;" [icon]="scrollDownIcon" size="2x"></fa-icon>
                    </div>
                </div>
            </div>
        </header>
        <div id="filterTriggerExpand"></div>

        <div class="px-3 sticky-top" style="background-color: #D3DBE3; border-top: 17px solid #FBB900">

            <div style="position: relative">
                <button id="expand-filter" (click)="manuallyOpen = true; isCollapsed = !isCollapsed" [style.opacity]="isCollapsed ? '1' : '0'" class="text-center text-white" style="background-color: #FBB900 ;position: absolute; left: calc(50% - 18px); top: -15px ;width: 36px; height: 36px; border-radius: 50%; display: inline-flex; justify-content: center; align-items: center;">
                    <fa-icon [icon]="filterIcon" style="font-size: 18px"></fa-icon>
                </button>
                <div class="overflow-hidden" id="filter-box"  [ngbCollapse]="isCollapsed">
                    <app-filters2></app-filters2>
                </div>
            </div>
            <button id="reduce-filter" (click)="manuallyOpen = true; isCollapsed = !isCollapsed" [style.opacity]="!isCollapsed ? '1' : '0'" class="text-center text-white" style="background-color: #FBB900 ;position: absolute; left: calc(50% - 18px); bottom: -22px ;width: 36px; height: 36px; border-radius: 50%; display: inline-flex; justify-content: center; align-items: center;">
                <fa-icon [icon]="collapseIcon" style="font-size: 18px"></fa-icon>
            </button>
        </div>

        <div id="course-list">
            <app-course-list2></app-course-list2>
        </div>
    </div>
    <div id="side-bar" class="bg-blue text-white p-3" style="display: flex; justify-content: center; align-items: center">
        <div id="form-container">
            <app-contact-form2></app-contact-form2>
        </div>
    </div>
    <footer id="footer" class="bg-dark border-top">
        <div style="height: 110px; /* display: flex; justify-content: center; align-items: center */" class="bg-white">
            <div class="row justify-content-center no-gutters h-100 align-items-center">
                <div class="col-2 col-sm-3 col-md-1">
                    <img style="max-height: 100px" alt="ONU Accreditation" class="img-fluid" src="assets/accreditations/onu.jpeg">
                </div>
                <div class="col-2 col-sm-3 col-md-1">
                    <img style="max-height: 100px" alt="USR Lazio Ufficio Scolastico Regionale Accreditation" class="img-fluid" src="assets/accreditations/SSM-Rome-USR-Lazio-600x403.jpeg">
                </div>
                <div class="col-2 col-sm-3 col-md-1">
                    <img style="max-height: 100px" alt="Eduqua Accreditation" class="img-fluid" src="assets/accreditations/SSM-swiss-eduqua-accreditation-600x403.jpeg">
                </div>
                <div class="col-2 col-sm-3 col-md-1">
                    <img style="max-height: 100px" alt="IACBE Accreditation" class="img-fluid" src="assets/accreditations/SSM-swiss-iacbe-accreditation-600x403.jpeg">
                </div>
                <div class="col-2 col-sm-3 col-md-1">
                    <img style="max-height: 100px" alt="International Association Universities Accreditation" class="img-fluid" src="assets/accreditations/Webp.net-resizeimage-5-600x403.jpg">
                </div>
            </div>
        </div>
        <div class="text-center text-white-50" style="height: 90px; display: flex; justify-content: center; align-items: center">
            <div>
                <div>© {{year}} Swiss School of Management. <a href="https://www.iubenda.com/privacy-policy/13761172" target="_blank">Privacy Policy</a> - <a target="_blank" href="https://www.iubenda.com/privacy-policy/13761172/cookie-policy/">Cookie Policy</a></div>
                <div class="row no-gutters justify-content-center px-5">
                    <div class="col-2 text-center">
                        <a href="https://twitter.com/ssmrome" target="_blank"><fa-icon [icon]="twitterIcon"></fa-icon></a>
                    </div>
                    <div class="col-2 text-center">
                        <a href="https://www.facebook.com/swisschoolofmanagement/" target="_blank"><fa-icon [icon]="facebookIcon"></fa-icon></a>
                    </div>
                    <div class="col-2 text-center">
                        <a href="https://www.linkedin.com/school/swiss-school-of-management/" target="_blank"><fa-icon [icon]="linkedinIcon"></fa-icon></a>
                    </div>
                    <div class="col-2 text-center">
                        <a href="https://www.instagram.com/ssmrome/" target="_blank"><fa-icon [icon]="instagramIcon"></fa-icon></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>

<ng-template #calendly>
    <div class="bg-white">
        <app-calendly></app-calendly>
    </div>
</ng-template>

<ng-template #genericForm>
    <app-generic-form2></app-generic-form2>
</ng-template>
