import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  img = {
    default: 'assets/NewLanding/AdobeStock_315800964.jpg',
    rome: '',
    madrid: ''
  };

  constructor() { }
}
