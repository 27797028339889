import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {NgbCollapseModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CourseListComponent } from './front/home/course-list/course-list.component';
import { HomeLayoutComponent } from './front/home/home-layout/home-layout.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { ContactFormComponent } from './front/home/contact-form/contact-form.component';
import { DashboardLayoutComponent } from './admin/dashboard-layout/dashboard-layout.component';
import { LoginComponent } from './admin/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CoursesManagerComponent } from './admin/courses-manager/courses-manager.component';
import { CourseManagerListComponent } from './admin/course-manager-list/course-manager-list.component';
import { NewCourseComponent } from './admin/new-course/new-course.component';
import { EditCourseComponent } from './admin/edit-course/edit-course.component';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import { FiltersComponent } from './front/home/filters/filters.component';
import { ThankYouPageComponent } from './front/thank-you-page/thank-you-page.component';
import { FooterComponent } from './front/footer/footer.component';
import * as smoothscroll from 'smoothscroll-polyfill';
import {GtagModule} from 'angular-gtag';
import { GenericFormComponent } from './front/generic-form/generic-form.component';
import {HttpClientModule} from '@angular/common/http';
import { CalendlyComponent } from './calendly/calendly.component';
import { Landing3Component } from './landing/landing3.component';
import { ContactForm2Component } from './landing/contact-form2/contact-form2.component';
import { Filters2Component } from './landing/filters2/filters2.component';
import { CourseList2Component } from './landing/course-list2/course-list2.component';
import { GenericForm2Component } from './landing/generic-form2/generic-form2.component';
import { Thankyou2Component } from './landing/thankyou2/thankyou2.component';
import { BandoPageComponent } from './bando-page/bando-page.component';
import { CampusManagerComponent } from './admin/campus-manager/campus-manager.component';

smoothscroll.polyfill();

@NgModule({
  declarations: [
    AppComponent,
    CourseListComponent,
    HomeLayoutComponent,
    ContactFormComponent,
    DashboardLayoutComponent,
    LoginComponent,
    CoursesManagerComponent,
    CourseManagerListComponent,
    NewCourseComponent,
    EditCourseComponent,
    FiltersComponent,
    ThankYouPageComponent,
    FooterComponent,
    GenericFormComponent,
    CalendlyComponent,
    Landing3Component,
    ContactForm2Component,
    Filters2Component,
    CourseList2Component,
    GenericForm2Component,
    Thankyou2Component,
    BandoPageComponent,
    CampusManagerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    FontAwesomeModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    GtagModule.forRoot({ trackingId: 'G-KH8QLG9ZR9', trackPageviews: false}),
  ],
  providers: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
