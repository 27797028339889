import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampusService {
  campus$: Observable<{name: string}[]>;

  constructor(private afs: AngularFirestore) { 
    
    this.campus$ = this.afs.collection<{name: string}>('campus').valueChanges();
  }
}
