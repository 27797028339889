import { Component, OnInit } from '@angular/core';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {CourseManagerService} from '../../services/course-manager.service';
import {Observable} from 'rxjs';
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';

@Component({
  selector: 'app-course-manager-list',
  templateUrl: './course-manager-list.component.html',
  styleUrls: ['./course-manager-list.component.scss']
})
export class CourseManagerListComponent implements OnInit {
  addIcon = faPlus;
  visibleIcon = faCircle;
  courses$: Observable<any[]>;
  languages;

  constructor(private courseManager: CourseManagerService) { }

  ngOnInit(): void {
    this.courses$ = this.courseManager.getAll();
    this.languages = {
      english: '../../../../assets/flags/gb.png',
      italian: '../../../../assets/flags/it.png',
    };
  }

  getFlag(lan) {
    return this.languages[lan];
  }
}
