<h2 class="text-blue text-center mb-5">New course</h2>


<div class="container">
  <div class="card">
    <div class="card-header">Course Information</div>
    <div class="card-body">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">


        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label for="title">Title</label>
              <input type="text" id="title" formControlName="title" class="form-control">
            </div>
          </div>
          <div class="col-md-2">
            <label for="priority" ngbTooltip="Courses with higher priority will be showed before">Priority <span style="border-radius: 50%;padding: 2px 9px" class="text-white small bg-info text-center">?</span></label>
            <select id="priority" formControlName="priority" class="form-control">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div class="col-md-2">
            <label for="visible">Published</label>
            <input type="checkbox" id="visible" formControlName="visible" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="category">Category</label>
              <select id="category" formControlName="category" class="form-control">
                <option value="" selected disabled>Select program (required)</option>
                <option value="mba">MBA - Master in Business & Administration</option>
                <option value="mba-global-management-leadership">MBA - Global Management & Leadership</option>
                <option value="mba-finance">MBA - Finance</option>
                <option value="mba-entrepreneurship">MBA - Entrepreneurship</option>
                <option value="mba-human-resources">MBA - Human Resources</option>
                <option value="mba-fintech">MBA - Financial Technology</option>
                <option value="mba-health-safety-environment">MBA - Health, Safety and Environment</option>
                <option value="mba-luxury-management">MBA - Luxury Management</option>
                <option value="mba-marketing">MBA - Marketing</option>
                <option value="mba-no-profit-organization">MBA - Non for Profit Organizations</option>
                <option value="mba-project-management">MBA - Project Management</option>
                <option value="mba-logistics">MBA - Logistics Management</option>
                <option value="bba">BBA - Bachelor Degree in Business & Administration</option>
                <option value="dba">DBA - Doctorate of Business Administration</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="formula">Formula</label>
              <select id="formula" formControlName="formula" class="form-control">
                <option value="" disabled selected>Select a lormula</option>
                <option value="full-time">Full time</option>
                <option value="part-time">Part-time</option>
              </select>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="language">Language</label>
              <select id="language" formControlName="language" class="form-control">
                <option value="" disabled selected>Select a language</option>
                <option value="english">English</option>
                <option value="italian">Italian</option>
              </select>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label for="location">Campus</label>
                <app-campus-manager [selectedCampus]="" (selected)="updateCampus($event)"></app-campus-manager>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="date">Date</label>
              <div class="input-group">
                <input id="date" class="form-control" placeholder="yyyy-mm-dd" readonly (click)="d.toggle()"
                       name="dp" [(ngModel)]="dateModel" ngbDatepicker #d="ngbDatepicker" [ngModelOptions]="{standalone: true}">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                    <fa-icon [icon]="calendarIcon"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <label for="text">Description</label>
        <input id="text" type="hidden">
        <trix-editor input="text" style="min-height: 300px"></trix-editor>

        <div class="form-group text-center my-5">
          <button type="button" class="btn btn-dark mx-2" routerLink="..">Back</button>
          <button class="btn btn-swiss" [disabled]="form.invalid || !dateModel">Create new</button>
        </div>
      </form>
    </div>
  </div>
</div>
