<ng-container *ngIf="courseService.results$ | async; let courses">
<!--    <button (click)="courseService.updateAll()">UPDATE</button>-->
    <ng-container *ngIf="courses.length > 0; else noResults">
        <div class="container">
            <div class="row px-3 justify-content-center">
                <div *ngFor="let c of courses; let i = index" [ngClass]="c.expanded ? 'col-md-12' : 'col-md-6'" class="p-3" [id]="'course-' + i">
                    <div class="border" style="border-radius: 16px; overflow: hidden">
                        <h4 class="bg-blue text-white p-3 mb-0" style="font-size: 20px">{{c.category | uppercase | slice:0:3}}</h4>
                        <div
                                [style.background-image]="c.category.includes('mba') ? 'url(\'assets/NewLanding/mba.webp\')' : c.category.includes('bba') ? 'url(\'assets/NewLanding/bba.webp\')' :  'url(\'assets/NewLanding/dba.webp\')'"
                                style="position: relative ;height: 200px; background-size: cover; background-position: center">
                            <div class="slanted pt-4 pb-3" [ngClass]="c.category.includes('mba') ? 'mba' : c.category.includes('bba') ? 'bba' : 'dba'">

                                <div class="slanted-container text-left pl-3" >
                                    <h4 class="text-white" style="font-size: 16px">{{c.title}}</h4>
                                </div>

                            </div>
                        </div>
                        <div class="pb-3 px-3 bg-white text-blue">
                            <div [hidden]="c.expanded" class="pt-3">
                                <h5 class="text-blue">{{c.category | uppercase | slice:0:3}}</h5>
                                <p style="font-size: 16px">
                                    {{c.formula | titlecase}} {{c.category | uppercase | slice:0:3}}
                                </p>
                            </div>

                            <div style="height: 1px; z-index: 100" class="border position-relative">
                                <button (click)="c.expanded = !c.expanded; scroll(i)" class="btn text-center text-white" style="background-color: #FBB900;line-height: 20px ;position: absolute; left: calc(50% - 25px); top: -25px ;width: 50px; height: 50px; border-radius: 50%">
                                    <fa-icon [icon]="c.expanded ? showLessIcon : showMoreIcon" style="font-size: 25px"></fa-icon>
                                </button>
                            </div>

                            <div class="mt-4 p-3">
                                <div class="row">
                                    <div class="col-6 text-right">
                                        Intake
                                    </div>
                                    <div class="col-6 font-weight-bold">
                                        {{(c.date.setMonth(c.date.getMonth()+1) === 7 ? c.date.setMonth(c.date.getMonth()+2) : c.date) | date:'MMMM'}}
                                    </div>
                                    <ng-container *ngIf="c.location === 'Online'; else onCampus">
                                        <div class="col-6 text-right">
                                            Mode
                                        </div>
                                        <div class="col-6 font-weight-bold">
                                            Online
                                        </div>
                                    </ng-container>
                                    <ng-template #onCampus>
                                        <div class="col-6 text-right">
                                            Campus
                                        </div>
                                        <!-- <pre>{{c.campusList | json}}</pre> -->
                                        <div class="col-6 font-weight-bold">
                                            <span *ngFor="let campus of toKeys(c.campusList).sort(); let i = index">{{campus | titlecase}}<span *ngIf="i < toKeys(c.campusList).length - 1">, </span></span>
                                        </div>
                                    </ng-template>
                                    <div class="col-6 text-right" [hidden]="!c.expanded">
                                        Language
                                    </div>
                                    <div class="col-6 font-weight-bold" [hidden]="!c.expanded">
                                        English
                                    </div>
                                    <div class="col-6 text-right" [hidden]="!c.expanded">
                                        Formula
                                    </div>
                                    <div class="col-6 font-weight-bold" [hidden]="!c.expanded">
                                        {{c.formula.replace('-', ' ') | titlecase}}
                                    </div>
                                    <div class="col-6 text-right" [hidden]="!c.expanded || c.location === 'Online'">
                                        Mode
                                    </div>
                                    <div class="col-6 font-weight-bold" [hidden]="!c.expanded || c.location === 'Online'">
                                        {{(c.location === 'rome' ? 'rome campus' : c.location)| titlecase}}
                                    </div>
                                    <div class="col-6 text-right" [hidden]="!c.expanded">
                                        Payment
                                    </div>
                                    <div class="col-6 font-weight-bold" [hidden]="!c.expanded">
                                        Flexible
                                    </div>
                                </div>
                            </div>
                            <div class="border" [hidden]="!c.expanded"></div>
                            <div [hidden]="!c.expanded" class="py-3">
                                <p [innerHTML]="c.description"></p>
                            </div>
                        </div>
                        <button (click)="selectedCourse = c; selectCourse();" class="d-none d-sm-block btn bg-blue text-white text-center p-3 mb-0" style="font-size: 20px; width: 100%">
                            Request info
                        </button>
                        <button (click)="selectedCourse = c; selectCourse(); openModal(form)" class="d-block d-sm-none btn bg-blue text-white text-center p-3 mb-0" style="font-size: 20px; width: 100%">
                            Request info
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #noResults>
        <div class="py-5 text-center">
            No course match these filters.
        </div>
    </ng-template>

</ng-container>


<ng-template #form>
    <app-contact-form2></app-contact-form2>
</ng-template>

