import { Component, OnInit } from '@angular/core';
import {faInstagram, faLinkedin, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faFacebook} from '@fortawesome/fontawesome-free-brands';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bando-page',
  templateUrl: './bando-page.component.html',
  styleUrls: ['./bando-page.component.scss']
})
export class BandoPageComponent implements OnInit {
  selectedCourse;
  year = new Date().getFullYear();
  twitterIcon = faTwitter;
  facebookIcon = faFacebook;
  linkedinIcon = faLinkedin;
  instagramIcon = faInstagram;
  scrollDownIcon = faChevronDown;

  constructor(private modal: NgbModal) { }

  ngOnInit(): void {
    const sideBar = document.getElementById('side-bar');
    const form = document.getElementById('form-container');
    window.onscroll = (ev) => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 200) {
        sideBar.style.position = 'absolute';
        sideBar.style.top = '-200px';
        form.style.position = 'absolute';
        form.style.right = '0';
        form.style.bottom = '0';
        sideBar.style.height = '100%';
      } else {
        sideBar.style.height = '100vh';
        form.style.bottom = 'unset';
        sideBar.style.top = '0px';
        sideBar.style.top = '0px';
        sideBar.style.position = 'fixed';
      }
    };
  }

  openModal(content) {
    this.modal.open(content);
  }

  updateForm(data) {
    console.log(data);
    this.selectedCourse = data;
  }

  scrollDown() {
    const header = document.getElementById('header');
    window.scrollTo({
          top: header.clientHeight,
          behavior: 'smooth',
        }
    );
  }
}
