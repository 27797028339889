  <nav class="navbar navbar-expand-lg navbar-light p-md-5 mb-4 sticky-top bg-white" style="z-index: 999;">
  <a class="navbar-brand" href="#"><img src="../../../../assets/SSM-rome-logo.png" class="img-fluid"></a>
</nav>

<div class="position-relative">
  <div class="d-none d-sm-block sticky-top" id="side-bar-container">
    <div id="side-bar" class="p-3">
      <div id="right-content" class="px-3 mb-5 pb-5">
        <div class="text-center">
          <img src="../../../../assets/colosseum.png" class="img-fluid" style="height: 250px">
        </div>
        <app-contact-form></app-contact-form>
      </div>


    </div>
  </div>

  <div id="main" class="mb-5">
    <div class="px-md-5 px-3">
      <h1 class="text-blue font-weight-bold">
        Swiss School of Management's Masters
      </h1>
      <p class="text-blue">
        We're the first European business school to be accredited by the DEAC and the only one dedicated to investing in our students' growth, not just as leaders in the business world, but as empowered individuals in all aspects of life.
      </p>
    </div>

      <div class="bg-orange d-md-none text-center sticky-top mb-4" style="top: 79px">
        <!-- <button class="btn btn-outline-light my-2" (click)="openModal(genericForm)">Request info</button>
        <span class="mx-3">OR</span> -->
        <button class="btn btn-outline-light" (click)="openModal(calendly)">Book a call</button>
      </div>

    <div class="p-3 sticky-top px-md-5 d-none d-md-block" style="top: 168px;">
      <div class="py-3 bg-orange border rounded px-3 text-center">
        <!-- <button class="btn-lg btn btn-outline-light" (click)="openModal(genericForm)">Request info</button>
        <span class="mx-3">OR</span> -->
        <button class="btn-lg btn btn-outline-light" (click)="openModal(calendly)">Book a call</button>
      </div>
    </div>


    <div class="px-md-5 px-3 pt-3">
      <div class="text-blue mb-0">
        <p class="font-weight-bold">Choose your favorite program and request more information.</p>
      </div>

      <div class="d-none d-md-block">
        <app-filters></app-filters>
      </div>

      <app-course-list></app-course-list>

    </div>
  </div>

  <ng-template #form>
    <app-contact-form></app-contact-form>
  </ng-template>

  <ng-template #genericForm>
    <app-generic-form></app-generic-form>
  </ng-template>

  <ng-template #calendly>
    <app-calendly></app-calendly>
  </ng-template>

  <app-footer></app-footer>
</div>
