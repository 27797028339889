import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModeService {
  modes = ['Online', 'On Campus', 'Blended'];

  constructor() { }
}
