import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  returnUrl;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl;
    });
  }

  ngOnInit() {
  }

  handleLogIn() {
    this.authService.login(this.email, this.password)
      .then(() => {
        this.router.navigate([this.returnUrl ? this.returnUrl : '/dashboard']);
      })
      .catch(e => {
        console.log(e);
      });
  }

  getError() {
    return '';
  }
}
