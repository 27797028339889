<div style="height: 100vh; display: flex; justify-content: center; align-items: center">
    <div class="container">
        <div class="row no-gutters justify-content-center text-center">
            <div class="col-sm-8 mb-3 pr-sm-4">
                <fa-icon [icon]="mailIcon" size="4x" class="text-blue"></fa-icon>
                <h1 class="text-blue font-weight-bold my-4">Thank You for Requesting Information.</h1>
                <h4 class="text-black-50 pr-sm-4">
                    Thank you for your interest in our courses, we will call you soon.<br>
                    In the meanwhile, click on the button below to find out more about our courses.
                </h4>

                <a href="https://ssm.swiss/academics/" class="btn text-white px-5 mb-2 font-weight-bold btn-landing">
                    Discover more
                </a>
<!--                <div class="py-3 text-center">-->
<!--                    <a class="btn btn-swiss btn-lg text-white font-weight-bold" href="https://ssm.swiss/academics/">DISCOVER MORE</a>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</div>
