import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  selectedCourse$: BehaviorSubject<any | null>;
  results$: Observable<any[]>;
  private categoryFilter$: BehaviorSubject<string|null>;
  private formulaFilter$: BehaviorSubject<string|null>;
  private locationFilter$: BehaviorSubject<string|null>;
  private campusFilter$: BehaviorSubject<string|null>;

  constructor(private db: AngularFirestore) {
    this.selectedCourse$ = new BehaviorSubject<any|null>(null);

    this.categoryFilter$ = new BehaviorSubject(null);
    this.formulaFilter$ = new BehaviorSubject(null);
    this.locationFilter$ = new BehaviorSubject(null);
    this.campusFilter$ = new BehaviorSubject(null);

    this.results$ = combineLatest(
      this.categoryFilter$,
      this.formulaFilter$,
      this.locationFilter$,
      this.campusFilter$,
    ).pipe(
      switchMap(([category, formula, location, campus]) => {
        return db.collection('courses', ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          if (category) {
            query = query.where('category', '==', category);
          }
          if (formula) {
            query = query.where('formula', '==', formula);
          }
          if (location) {
            query = query.where('location', '==', location);
          }
          if (campus) {
            query = query.where('campusList.' + campus, '==', true);
          }
          query = query.where('visible', '==', true).orderBy('priority', 'desc');
          return query;
        }).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as any;
            data.expanded = false;
            data.date = new Date();
            data.date.setDate(1);
            const id = a.payload.doc.id;
            return { id, ...data };
          }))
        );
      })
    );
  }

  applyFilters(filters) {
    this.categoryFilter$.next(filters.category ? filters.category : null);
    this.locationFilter$.next(filters.location ? filters.location : null);
    this.formulaFilter$.next(filters.formula ? filters.formula : null);
    this.campusFilter$.next(filters.campus ? filters.campus : null);
  }

  selectCourse(data) {
    this.selectedCourse$.next(data);
  }

  updateAll() {
    this.db.collection('courses').get().toPromise().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().campus === 'madrid')  {
          doc.ref.set({
            visible: false
          }, {merge: true});
        }
      });
    });
  }
}
