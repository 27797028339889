import { Component, OnInit } from '@angular/core';
import {faCalendar} from '@fortawesome/free-regular-svg-icons/faCalendar';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CourseManagerService} from '../../services/course-manager.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-new-course',
  templateUrl: './new-course.component.html',
  styleUrls: ['./new-course.component.scss']
})
export class NewCourseComponent implements OnInit {
  form: FormGroup;
  dateModel: NgbDateStruct;
  calendarIcon = faCalendar;
  date: {year: number, month: number};

  constructor(private fb: FormBuilder, private courseManager: CourseManagerService, private router: Router) { }

  ngOnInit(): void {
    this.createForm();
  }

  updateCampus(campusList: any[]) {
    const list = [];
    for(let c of campusList) {
      list[c.name.toLowerCase()] = true;
    }
    console.log(list);
    
    this.form.controls['campusList'].setValue(list);

    console.log(this.form.controls['campusList'].value);
    
    
  }

  createForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      visible: [false, Validators.required],
      language: ['', Validators.required],
      category: ['', Validators.required],
      campusList: [''],
      formula: ['', Validators.required],
      priority: [1, Validators.required]
    });
  }

  onSubmit() {
    const tmpData = this.form.getRawValue();
    tmpData.priority = +tmpData.priority;
    tmpData.date =  new Date(this.dateModel.year + '-' + this.dateModel.month + '-' + this.dateModel.day);
    const editor = document.querySelector('trix-editor');
    // @ts-ignore
    tmpData.description = editor.value;

    const campusMap = {}

    for(let c in tmpData.campusList) {
      campusMap[c] = true;
    }
    console.log(campusMap);

    tmpData.campusList = campusMap;

    this.courseManager.createNew(tmpData)
      .then(() => {
        this.router.navigate(['/admin']);
      });
  }
}
