<div class="text-right pt-5 pr-5">
  <button class="btn btn-swiss" routerLink="../add-new"><fa-icon [icon]="addIcon"></fa-icon> Add new</button>
</div>

<h2 class="text-center text-blue mb-5">All courses</h2>

<div class="table-responsive">
  <table class="table table-hover">
    <thead class="bg-blue text-white">
    <tr>
      <th scope="col">Title</th>
      <th scope="col">Category</th>
      <th scope="col">Date</th>
      <th scope="col">Formula</th>
      <th scope="col">Location</th>
      <th scope="col">Language</th>
      <th scope="col">Priority</th>
      <th scope="col">Visible</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="courses$ | async; let courses; else loading">
      <tr *ngFor="let c of courses">
        <th scope="row">{{c.title}}</th>
        <td>{{c.category | titlecase}}</td>
        <td>{{c.date.seconds*1000 | date}}</td>
        <td>{{c.formula | titlecase}}</td>
        <td>{{c.location | titlecase}}</td>
        <td>
          <img [src]="getFlag(c.language)" class="img-fluid flag">
        </td>
        <td>
          {{c?.priority}}
        </td>
        <td>
          <fa-icon [icon]="visibleIcon" [ngClass]="c.visible ? 'text-success' : 'text-dark'"></fa-icon>
        </td>
        <td>
          <button class="btn btn-sm btn-outline-warning" routerLink="../edit-course/{{c.id}}">Edit</button>
        </td>
      </tr>
    </ng-container>
    <ng-template #loading>
      <td colspan="9">Loading</td>
    </ng-template>
    </tbody>
  </table>
</div>
