import { Component, OnInit } from '@angular/core';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons/faChevronUp';
import {CoursesService} from '../../services/courses.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';

@Component({
  selector: 'app-course-list2',
  templateUrl: './course-list2.component.html',
  styleUrls: ['./course-list2.component.scss']
})
export class CourseList2Component implements OnInit {

  languages;
  locationIcons;
  selectedCourse;
  form;
  sideBar;
  expandIcon = faChevronDown;
  compressIcon = faChevronUp;
  showMoreIcon = faPlus;
  showLessIcon = faTimes;

  constructor(public courseService: CoursesService, private modal: NgbModal) { }

  ngOnInit(): void {
    this.form = document.getElementById('form');
    this.sideBar = document.getElementById('side-bar');
    this.languages = {
      english: '../../../../assets/flags/gb.png',
      italian: '../../../../assets/flags/it.png',
    };
    this.locationIcons = {
      rome: '../../../../assets/icon-rome.svg',
      online: '../../../../assets/icon-online.svg'
    };
  }

  openModal(content) {
    this.modal.open(content);
  }

  getLocationIcon(loc) {
    return this.locationIcons[loc];
  }

  getFlag(lan) {
    return this.languages[lan];
  }

  selectCourse() {
    if (this.form) {
      this.form.classList.remove('bg-blue');
      this.form.classList.add('bg-orange');
      this.sideBar.classList.remove('bg-blue');
      this.sideBar.classList.add('bg-orange');
      setTimeout(() => {
        this.form.classList.add('bg-blue');
        this.form.classList.remove('bg-orange');
        this.sideBar.classList.add('bg-blue');
        this.sideBar.classList.remove('bg-orange');
      }, 1000);
    }
    this.courseService.selectCourse(this.selectedCourse);
  }

  scroll(id: number) {
    setTimeout(() => {
      document.getElementById('course-' + id).scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }, 100);
  }

  toKeys(object: any): any[] {
    if (object) {
      let res = Object.entries(object);
      res = res.filter(obj => {
        return obj[1];
      });
      return res.map(arr => arr[0]);
    } else {
      return [];
    }
  }
}
