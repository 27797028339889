import { Component, OnInit } from '@angular/core';
import {faEnvelope} from "@fortawesome/free-regular-svg-icons/faEnvelope";

@Component({
  selector: 'app-thankyou2',
  templateUrl: './thankyou2.component.html',
  styleUrls: ['./thankyou2.component.scss']
})
export class Thankyou2Component implements OnInit {
  mailIcon = faEnvelope;

  constructor() { }

  ngOnInit(): void {
  }

}
