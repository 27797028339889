import {Component, OnInit} from '@angular/core';
import {CoursesService} from '../../../services/courses.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons/faChevronUp';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent implements OnInit {

  languages;
  locationIcons;
  selectedCourse;
  form;
  expandIcon = faChevronDown;
  compressIcon = faChevronUp;

  constructor(public courseService: CoursesService, private modal: NgbModal) { }

  ngOnInit(): void {
    this.form = document.getElementById('form');
    this.languages = {
      english: '../../../../assets/flags/gb.png',
      italian: '../../../../assets/flags/it.png',
    };
    this.locationIcons = {
      rome: '../../../../assets/icon-rome.svg',
      online: '../../../../assets/icon-online.svg'
    };
  }

  openModal(content) {
    this.modal.open(content);
  }

  getLocationIcon(loc) {
    return this.locationIcons[loc];
  }

  getFlag(lan) {
    return this.languages[lan];
  }

  selectCourse() {
    if (this.form) {
      this.form.classList.remove('bg-blue');
      this.form.classList.add('bg-blue-on-change');
      setTimeout(() => {
        this.form.classList.add('bg-blue');
        this.form.classList.remove('bg-blue-on-change');
      }, 1000);
    }
    this.courseService.selectCourse(this.selectedCourse);
  }

  handleScroll(i) {
    if (i === 1) {
      this.scroll();
    }
  }

  scroll() {
    const component = document.getElementById('footer');
    const bodyRect = document.body.getBoundingClientRect().top;
    const componentRect = component.getBoundingClientRect().top;
    const componentPosition = componentRect - bodyRect;
    window.scroll({
      top: (componentPosition - 1000),
      left: 0,
      behavior: 'smooth'
    });
  }

}
