import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faInstagram, faLinkedin, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faFacebook} from '@fortawesome/fontawesome-free-brands';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {HeaderService} from "../services/header.service";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faFilter} from "@fortawesome/free-solid-svg-icons/faFilter";

@Component({
  selector: 'app-landing3',
  templateUrl: './landing3.component.html',
  styleUrls: ['./landing3.component.scss']
})
export class Landing3Component implements OnInit {
  selectedCourse;
  year = new Date().getFullYear();
  twitterIcon = faTwitter;
  facebookIcon = faFacebook;
  linkedinIcon = faLinkedin;
  instagramIcon = faInstagram;
  scrollDownIcon = faChevronDown;
  collapseIcon = faChevronUp;
  filterIcon = faFilter;
  manuallyOpen = false;
  isCollapsed = false;

  constructor(private modal: NgbModal, public headerService: HeaderService) { }

  ngOnInit(): void {
    const sideBar = document.getElementById('side-bar');
    const form = document.getElementById('form-container');
    window.onscroll = (ev) => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 200) {
        sideBar.style.position = 'absolute';
        sideBar.style.top = '-200px';
        form.style.position = 'absolute';
        form.style.right = '0';
        form.style.bottom = '0';
        sideBar.style.height = '100%';
      } else {
        sideBar.style.height = '100vh';
        form.style.bottom = 'unset';
        sideBar.style.top = '0px';
        sideBar.style.top = '0px';
        sideBar.style.position = 'fixed';
      }
    };

    // window.addEventListener('scroll', (ev) => {
    //
    //   const filterDiv = document.getElementById('filterTriggerExpand');
    //   const distanceToTop = filterDiv.getBoundingClientRect().top;
    //
    //   if (distanceToTop < -100 && !this.manuallyOpen) {
    //     this.isCollapsed = true;
    //   } else if (distanceToTop > 100) {
    //     this.isCollapsed = false;
    //     this.manuallyOpen = false;
    //   }
    // });
  }

  openModal(content) {
    this.modal.open(content);
  }

  updateForm(data) {
    console.log(data);
    this.selectedCourse = data;
  }

  scrollDown() {
    const header = document.getElementById('header');
    window.scrollTo({
         top: header.clientHeight,
      behavior: 'smooth',
        }
    );
  }
}
