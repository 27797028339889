<nav class="navbar navbar-expand-lg navbar-light bg-light shadow fixed-top">
  <a class="navbar-brand">
    <img class="img-fluid" src="../../../assets/SSM-rome-round-logo.png">
    Swiss School of Management
  </a>
  <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="courses" routerLinkActive="active">Corsi</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link clickable" (click)="logout()">Logout</a>
      </li>
    </ul>
  </div>
</nav>
<div id="background" class="py-5 bg-white">
  <div class="my-5">
    <router-outlet></router-outlet>
  </div>
</div>
