<div class="bg-blue p-3 py-md-3" id="form" style="z-index: 9999999">
  <div class="text-right d-md-none">
    <fa-icon [icon]="closeIcon" class="text-white" size="2x" (click)="closeModal()"></fa-icon>
  </div>
  <h5 class="text-white font-weight-bold text-center">Find out more!</h5>
  <p class="text-white font-weight-lighter mb-4">Please, fill in the form and we will contact you with further information on
    the Master of your choice
  </p>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <select class="form-control updateable" formControlName="category">
        <option value="" selected disabled>Select program (required)</option>
        <option value="mba">MBA - Master in Business & Administration</option>
        <option value="mba-global-management-leadership">MBA - Global Management & Leadership</option>
        <option value="mba-finance">MBA - Finance</option>
        <option value="mba-entrepreneurship">MBA - Entrepreneurship</option>
        <option value="mba-human-resources">MBA - Human Resources</option>
        <option value="mba-fintech">MBA - Financial Technology</option>
        <option value="mba-health-safety-environment">MBA - Health, Safety and Environment</option>
        <option value="mba-luxury-management">MBA - Luxury Management</option>
        <option value="mba-marketing">MBA - Marketing</option>
        <option value="mba-no-profit-organization">MBA - Non for Profit Organizations</option>
        <option value="mba-project-management">MBA - Project Management</option>
        <option value="mba-logistics">MBA - Logistics Management</option>
        <option value="bba">BBA - Bachelor Degree in Business & Administration</option>
      </select>
    </div>

    <div class="row no-gutters">
      <div class="col-6 pr-1">
        <div class="form-group">
          <select class="form-control updateable" formControlName="formula">
            <option value="" selected disabled>Select formula (required)</option>
            <option value="full-time">Full time</option>
            <option value="part-time" [disabled]="form.getRawValue().category === 'bba'">Part time</option>
          </select>
        </div>
      </div>
      <div class="col-6 pl-1">
        <div class="form-group">
          <select class="form-control updateable" formControlName="location">
            <option value="" selected disabled>Select mode (required)</option>
            <option value="rome">Rome campus</option>
            <option value="online">Online</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-6 pr-1">
        <div class="form-group">
          <input class="form-control" name="firstName" type="text" placeholder="Name (required)" formControlName="name">
        </div>
      </div>
      <div class="col-6 pl-1">
        <div class="form-group">
          <input class="form-control" name="lastName" type="text" placeholder="Surname (required)" formControlName="surname">
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-6 pr-1">
        <div class="form-group">
          <select class="form-control" formControlName="age" name="birthYear">
            <option value="" selected disabled>Select birth year (required)</option>
            <option *ngFor="let y of years" value="{{y}}">{{y}}</option>
          </select>
        </div>
      </div>
      <div class="col-6 pl-1 text-center">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="gender-male" value="male" name="gender" formControlName="gender">
          <label class="form-check-label text-white" for="gender-male">M</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineCheckbox2" value="female" name="gender" formControlName="gender">
          <label class="form-check-label text-white" for="inlineCheckbox2">F</label>
        </div>
      </div>
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <fa-icon [icon]="mailIcon"></fa-icon>
        </div>
      </div>
      <input class="form-control" type="text" placeholder="Email (required)" formControlName="email">
    </div>
    <div class="alert alert-danger mt-2" *ngIf="form.controls.email.touched && form.controls.email?.errors?.pattern">
      Please enter a valid email address
    </div>

    <!--<div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <fa-icon [icon]="phoneIcon"></fa-icon>
        </div>
      </div>
      <input type="text" class="form-control" placeholder="Phone number (required)" formControlName="phone">
    </div>-->

    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <fa-icon [icon]="phoneIcon"></fa-icon>
        </div>
      </div>
      <select class="form-control" formControlName="countryCode">
        <option value="" selected disabled>Area code</option>
        <option *ngFor="let c of countries" [value]="c.dial_code">{{flags.get(c.code).emoji}} {{c.name}} {{c.dial_code}}</option>
      </select>
      <input type="text" class="form-control w-50" formControlName="phone" placeholder="Phone number (required)">
    </div>
    <div class="alert alert-danger mt-2" *ngIf="form.controls.phone.touched && (form.controls.phone?.errors?.maxlength || form.controls.phone?.errors?.minlength)">
      Please enter a valid phone number
    </div>

    <p class="small text-white mt-3" style="font-size: 0.6rem!important;">
      Swiss School of Management will process your personal information to contact you and to inform you about the program of your choice for the upcoming two terms. Afterwards, your data will be deleted. You may exercise the rights of access, deletion, rectification, opposition, limitation and portability, by post to Swiss School of Management, Via Pellandini 4, 6500 Bellinzona Switzerland, or by email to info@ssm.swiss. Likewise, if the interested party considers it appropriate, they can lodge a claim to the Italian Data Protection Agency. Moreover, you can contact our Data Protection Manager by email to serena.magnanti@ssm.swiss or by post to Swiss School of Management, At.: Data Protection Manager, Via Pellandini 4, 6500, Bellinzona.
    </p>

    <div class="form-group text-center mt-3 mx-auto">
      <button class="btn-lg btn btn-swiss font-weight-bold" [disabled]="form.invalid">SUBMIT</button>
    </div>

  </form>
</div>
