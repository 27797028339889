<div class="row no-gutters my-3 border" style="border-radius: 16px; overflow: hidden">
    <div class="col-md-3 col-6 border-custom2" style="height: 100%">
        <div class="form-group mb-0 filter styled-select">
            <div class="input-group">
                <select class="form-control" [(ngModel)]="category" (ngModelChange)="submit()" style="height: 100%; border-radius: 0;">
                    <option value="" selected disabled>Select program</option>
                    <option value="">All courses</option>
                    <option *ngFor="let c of courseCategoryService.categories" [value]="c.value">{{c.name}}</option>
                </select>
                <span class="input-group-text">
                    <fa-icon [icon]="selectIcon"></fa-icon>
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-6 border-custom2" style="height: 100%">
        <div class="form-group mb-0 filter styled-select">
            <div class="input-group">
                <select class="form-control" [(ngModel)]="campus" (ngModelChange)="submit()" style="height: 100%; border-radius: 0;">
                    <option value="" selected disabled>Select Campus</option>
                    <option value="">All campus</option>
                    <option *ngFor="let c of campusList" [value]="c">{{c | titlecase}}</option>
                </select>
                <span class="input-group-text">
                    <fa-icon [icon]="selectIcon"></fa-icon>
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-6 border-custom2">
        <div class="form-group filter mb-0 styled-select" style="height: 100%;">
            <div class="input-group">
                <select id="formula" class="form-control" [(ngModel)]="formula" (ngModelChange)="submit()" style="height: 100%; border-radius: 0;">
                    <option value="" disabled selected>Select formula</option>
                    <option value="">All formulas</option>
                    <option value="full-time">Full time</option>
                    <option value="part-time">Part time</option>
                </select>
                <span class="input-group-text">
                    <fa-icon [icon]="selectIcon"></fa-icon>
                </span>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-6">
        <div class="form-group filter mb-0 styled-select" style="height: 100%">
            <div class="input-group">
                <select id="location" class="form-control" [(ngModel)]="location" (ngModelChange)="submit()" style="height: 100%; border-radius: 0;">
                    <option value="" disabled selected>Select mode</option>
                    <option value="">All modes</option>
                    <option *ngFor="let m of modeService.modes" [value]="m">{{m | titlecase}}</option>
                </select>
                <span class="input-group-text">
                    <fa-icon [icon]="selectIcon"></fa-icon>
                </span>
            </div>
        </div>
    </div>
<!--    <div class="col-md-3">-->
<!--        <div class="form-group mb-0" style="height: 100%">-->
<!--            <button class="btn text-white btn bg-blue" style="width: 100%; height: 100%; border-radius: 0;" (click)="submit()">-->
<!--                <fa-icon [icon]="searchIcon" class="mr-2"></fa-icon>Search-->
<!--            </button>-->
<!--        </div>-->
<!--    </div>-->
</div>
