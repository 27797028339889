<div class="bg-blue p-3 py-md-3" id="form">
    <div class="text-right d-md-none">
        <fa-icon [icon]="closeIcon" class="text-white" size="2x" (click)="closeModal()"></fa-icon>
    </div>
    <h5 class="text-white font-weight-bold text-center">Find out more!</h5>
    <p class="text-white font-weight-lighter mb-4">Please, fill in the form and we will contact you
    </p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row no-gutters">
            <div class="col-6 pr-1">
                <div class="form-group">
                    <input class="form-control" name="firstName" type="text" placeholder="Name *" formControlName="name">
                </div>
            </div>
            <div class="col-6 pl-1">
                <div class="form-group">
                    <input class="form-control" name="lastName" type="text" placeholder="Surname *" formControlName="surname">
                </div>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="col-6 pr-1">
                <div class="form-group">
                    <select class="form-control" formControlName="age" name="birthYear">
                        <option value="" selected disabled>Select birth year *</option>
                        <option *ngFor="let y of years" value="{{y}}">{{y}}</option>
                    </select>
                </div>
            </div>
            <div class="col-6 pl-1 text-center" style="height: 44px; display: flex; justify-content: center; align-items: center">
                <div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="gender-male" value="male" name="gender" formControlName="gender">
                        <label class="form-check-label text-white" for="gender-male">M</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="inlineCheckbox2" value="female" name="gender" formControlName="gender">
                        <label class="form-check-label text-white" for="inlineCheckbox2">F</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row no-gutters">
            <div class="col-md-6 pr-md-1">
                <div class="input-group mb-3">
                    <input class="form-control" type="text" placeholder="Email *" formControlName="email">
                </div>
            </div>
            <div class="col-md-6 pl-md-1 mb-3">

                <select class="form-control" formControlName="campus" name="campus">
                    <option value="" selected disabled>Select campus *</option>
                    <option *ngFor="let c of campusList" value="{{c}}">{{c | titlecase}}</option>
                </select>

            </div>
        </div>
        <div class="alert alert-danger mt-2" *ngIf="form.controls.email.touched && form.controls.email?.errors?.pattern">
            Please enter a valid email address
        </div>

        <!--<div class="input-group mb-3">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <fa-icon [icon]="phoneIcon"></fa-icon>
            </div>
          </div>
          <input type="text" class="form-control" placeholder="Phone number *" formControlName="phone">
        </div>-->

        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    <fa-icon [icon]="phoneIcon"></fa-icon>
                </div>
            </div>
            <select class="form-control" formControlName="countryCode" style="height: 46px">
                <option value="" selected disabled>Area code</option>
                <option *ngFor="let c of countries" [value]="c.dial_code">{{flags.get(c.code).emoji}} {{c.name}} {{c.dial_code}}</option>
            </select>
            <input type="text" class="form-control w-50" formControlName="phone" placeholder="Phone number *">
        </div>
        <div class="alert alert-danger mt-2" *ngIf="form.controls.phone.touched && (form.controls.phone?.errors?.maxlength || form.controls.phone?.errors?.minlength)">
            Please enter a valid phone number
        </div>

        <p class="small text-white mt-3" style="font-size: 0.6rem!important;">
            Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC.
        </p>

        <div class="form-check text-center">
            <input class="form-check-input" type="checkbox" formControlName="privacy" id="privacy">
            <label class="form-check-label text-white ml-2" for="privacy" style="font-size: 0.8rem!important;">
                I have read and accept the <a class="text-white" href="https://www.iubenda.com/privacy-policy/13761172" target="_blank">Privacy Policy</a>.
            </label>
        </div>

        <div class="form-group text-center mt-3 mx-auto">
            <button class="btn text-white font-weight-bold px-4" style="width: 199px; background: #FBB900 0% 0% no-repeat padding-box;border-radius: 30px;" [disabled]="form.invalid">SUBMIT</button>
        </div>

    </form>
</div>
