<div class="bg-blue position-relative">
    <div id="main" class="bg-white">
        <header id="header" style="min-height: 60vh; background-image: url('assets/NewLanding/colosseo_swissBusinessSchool.webp'); background-size: cover; padding: 10px 10px" class="p-lg-5 p-3; position-relative">
            <div class="pb-5" style="height: 100%; align-items: center; justify-content: center; display: flex">
                <div>
                    <img alt="Swiss School of Management" id="logo" src="assets/NewLanding/SSM®_Logo_2020_CMYK_Tavola%20disegno%201%20copia.svg" class="img-fluid mb-4" style="height: 100px" >

                    <h2 class="position-relative text-white mt-md-4" style="font-size: 56px; z-index: 10">We Create <span>Leaders</span></h2>

                    <div id="blu-box" class="p-3 text-white position-relative">
                        <h1 class="font-weight-bold" style="font-size: 21px; z-index: 10000">Assegnazione di 10 borse di studio finanziate dalla Swiss School of Management riservate a studentesse e studenti internazionali provenienti dall’Ucraina</h1>
                        <p style="font-size: 16px; z-index: 12211">
                            La Swiss School of Management, pubblica per l’anno accademico 2022/2023 l’assegnazione di 10 borse di studio in denaro del valore totale di 100.000 €, pari a 10.000 € a borsa di studio, a studenti ucraini under 20.                        </p>
                    </div>

<!--                    <div class="my-4 text-center text-sm-left">-->
<!--                        <button (click)="openModal(genericForm)" class="btn text-white px-5 mb-2 font-weight-bold btn-landing">-->
<!--                            Request info-->
<!--                        </button>-->
<!--                        <span class="mx-3 text-white d-none d-sm-inline font-weight-bold">or</span>-->
<!--                        <div class="my-1 text-white d-block d-sm-none font-weight-bold">or</div>-->
<!--                        <button (click)="openModal(calendly)" class="btn text-white px-5 mb-2 font-weight-bold btn-landing">-->
<!--                            Book a call-->
<!--                        </button>-->
<!--                    </div>-->

<!--                    <p class="text-white mt-sm-3 mt-2" style="font-size: 16px;">-->
<!--                        All programs run at Swiss School of Management are fully accredited and quality certified.<br>-->
<!--                    </p>-->
                </div>
            </div>
            <div class="position-absolute text-center text-white pb-sm-3" style="width: 100%; height: fit-content; bottom: 0; left: 0">
                <div>
                    <p class="d-none d-md-block">
                        Sopri i dettagli
                    </p>
                    <div class="bounce">
                        <fa-icon (click)="scrollDown()"  style="cursor:pointer;" [icon]="scrollDownIcon" size="2x"></fa-icon>
                    </div>
                </div>
            </div>
        </header>

        <div class="container py-5">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <h3>
                        Tipo
                    </h3>
                    <p>
                        BBA - Bachelor Business Administration (laurea triennale)
                    </p>

                    <h3>
                        Lingua di studio
                    </h3>
                    <p>
                        Inglese
                    </p>

                    <h3>
                        Qualifiche di studio
                    </h3>
                    <p>
                        Per accedere ai corsi di laurea e laurea magistrale è necessario essere in possesso di un diploma di scuola superiore.
                        I documenti di studio sono accettati nelle seguenti lingue: inglese<br>
                        Se i documenti sono rilasciati in una lingua differente da quelle sopra elencate, dovrai allegare sia il documento sia la traduzione ufficiale.
                    </p>

                    <h3>Requisiti in base al territorio</h3>
                    <p>
                        Questa finestra di candidatura è riservata ai cittadini ucraini residenti in Ucraina prima del 30 Settembre 2022 e ai cittadini ucraini titolari di protezione internazionale, prima del 30 Settembre 2022, in Ucraina.
                    </p>

                    <h3>
                        Requisiti linguistici
                    </h3>
                    <p>
                        Inglese
                    </p>

                    <h3>Altri requisiti</h3>
                    <p>
                        È necessario aggiungere una lettera di motivazione alla propria candidatura.
                        La lettera di motivazione deve essere scritta in lingua inglese.
                    </p>

                    <h3>I candidati devono:</h3>
                    <ul>
                        <li>avere tra i 18 e i 20 anni;</li>
                        <li>
                            essere in possesso di un permesso di soggiorno per protezione temporanea rilasciato in Italia (o aver presentato domanda di permesso di soggiorno per protezione temporanea in Italia), in conseguenza alla crisi internazionale Ucraina.
                        </li>
                    </ul>

                    <h3 class="mt-5">
                        Per informazioni contattare:
                    </h3>
                    <p>
                        Swiss School of Management<br>
                        Tel. 06 4004 9320<br>
                        mail: info@ssm.swiss<br>
                        Piazza del Biscione, 95<br>
                        00186, Roma RM
                    </p>
                </div>
            </div>
        </div>
    </div>
    <footer id="footer" class="bg-dark border-top">
        <div style="height: 110px; display: flex; justify-content: center; align-items: center" class="bg-white">
            <div class="row justify-content-center no-gutters">
                <div class="col-2 col-sm-3 col-md-1">
                    <img alt="ONU Accreditation" class="img-fluid" src="assets/accreditations/onu.jpeg">
                </div>
                <div class="col-2 col-sm-3 col-md-1">
                    <img alt="USR Lazio Ufficio Scolastico Regionale Accreditation" class="img-fluid" src="assets/accreditations/SSM-Rome-USR-Lazio-600x403.jpeg">
                </div>
                <div class="col-2 col-sm-3 col-md-1">
                    <img alt="Eduqua Accreditation" class="img-fluid" src="assets/accreditations/SSM-swiss-eduqua-accreditation-600x403.jpeg">
                </div>
                <div class="col-2 col-sm-3 col-md-1">
                    <img alt="IACBE Accreditation" class="img-fluid" src="assets/accreditations/SSM-swiss-iacbe-accreditation-600x403.jpeg">
                </div>
                <div class="col-2 col-sm-3 col-md-1">
                    <img alt="International Association Universities Accreditation" class="img-fluid" src="assets/accreditations/Webp.net-resizeimage-5-600x403.jpg">
                </div>
            </div>
        </div>
        <div class="text-center text-white-50" style="height: 90px; display: flex; justify-content: center; align-items: center">
            <div>
                <div>© {{year}} Swiss School of Management. <a href="https://www.iubenda.com/privacy-policy/13761172" target="_blank">Privacy Policy</a> - <a target="_blank" href="https://www.iubenda.com/privacy-policy/13761172/cookie-policy/">Cookie Policy</a></div>
                <div class="row no-gutters justify-content-center px-5">
                    <div class="col-2 text-center">
                        <a href="https://twitter.com/ssmrome" target="_blank"><fa-icon [icon]="twitterIcon"></fa-icon></a>
                    </div>
                    <div class="col-2 text-center">
                        <a href="https://www.facebook.com/swisschoolofmanagement/" target="_blank"><fa-icon [icon]="facebookIcon"></fa-icon></a>
                    </div>
                    <div class="col-2 text-center">
                        <a href="https://www.linkedin.com/school/swiss-school-of-management/" target="_blank"><fa-icon [icon]="linkedinIcon"></fa-icon></a>
                    </div>
                    <div class="col-2 text-center">
                        <a href="https://www.instagram.com/ssmrome/" target="_blank"><fa-icon [icon]="instagramIcon"></fa-icon></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>

<ng-template #calendly>
    <div class="bg-white">
        <app-calendly></app-calendly>
    </div>
</ng-template>

<ng-template #genericForm>
    <app-generic-form2></app-generic-form2>
</ng-template>
