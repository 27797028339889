import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpClient} from '@angular/common/http';
import {Gtag} from 'angular-gtag';

@Injectable({
  providedIn: 'root'
})
export class LeadService {
  locationUrl = 'https://api.geoapify.com/v1/ipinfo?&apiKey=88c16cbee38e43eb80ff2d9f8912144f';

  constructor(private db: AngularFirestore, private http: HttpClient, private gtag: Gtag) {
  }

  async storeLead(data) {
    const results = await this.getEmailCounter(data.email);
    if (results.size > 2 || data.email.includes('temporarymail.net') || data.email.includes('falltrack.net') || data.email.replace(/[^0-9]/g, '').length > 5) {
      return Promise.resolve();
    } else {
      this.gtag.event('form_submitted', {
        event_category: 'forms',
        event_label: 'RICHIESTA INFORMAZIONI'
      });
      return this.db.collection('leads').add(data);
    }
  }

  getEmailCounter(email: string) {
    return this.db.collection('leads', ref => ref.where('email', '==', email)).get().toPromise();
  }

  getLocation() {
    return this.http.get(this.locationUrl);
  }
}
