import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeLayoutComponent} from './front/home/home-layout/home-layout.component';
import {DashboardLayoutComponent} from './admin/dashboard-layout/dashboard-layout.component';
import {IsLoggedInService} from './services/is-logged-in.service';
import {LoginComponent} from './admin/login/login.component';
import {CoursesManagerComponent} from './admin/courses-manager/courses-manager.component';
import {CourseManagerListComponent} from './admin/course-manager-list/course-manager-list.component';
import {NewCourseComponent} from './admin/new-course/new-course.component';
import {EditCourseComponent} from './admin/edit-course/edit-course.component';
 
import {Landing3Component} from './landing/landing3.component';
import {Thankyou2Component} from './landing/thankyou2/thankyou2.component';
import {BandoPageComponent} from './bando-page/bando-page.component';


const routes: Routes = [
  {path: '', component: Landing3Component},
  {path: 'borsa-di-studio', component: BandoPageComponent},
  // {path: 'landing', component: Landing3Component},
  {path: 'thank-you', component: Thankyou2Component},
  {path: 'admin', component: DashboardLayoutComponent, canActivate: [IsLoggedInService],
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'courses'},
      {path: 'courses', component: CoursesManagerComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'list'},
          {path: 'list', component: CourseManagerListComponent},
          {path: 'add-new', component: NewCourseComponent},
          {path: 'edit-course/:id', component: EditCourseComponent}
        ]
      }
    ]
  },
  {path: 'login', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
