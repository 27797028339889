import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {faCalendar} from '@fortawesome/free-regular-svg-icons/faCalendar';
import {CourseManagerService} from '../../services/course-manager.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-edit-course',
  templateUrl: './edit-course.component.html',
  styleUrls: ['./edit-course.component.scss']
})
export class EditCourseComponent implements OnInit {
  form: FormGroup;
  dateModel: NgbDateStruct;
  calendarIcon = faCalendar;
  date: {year: number, month: number};
  course;
  id;
  formReady = false;

  constructor(
    private fb: FormBuilder, private courseManager: CourseManagerService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.id = params.id;
      const docSnapshot = await this.courseManager.getToPromise(params.id);
      // @ts-ignore
      this.course = docSnapshot.data();
      
      // @ts-ignore
      this.course.id = docSnapshot.id;
      // @ts-ignore
      const date = new Date((this.course.date as any).seconds * 1000);
      this.dateModel = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear()
      };
      this.createForm();
      this.formReady = true;
      setTimeout(() => {
        const element = document.querySelector('trix-editor');
        // @ts-ignore
        element.value = this.course.description ? this.course.description : '';
      }, 1000);
    });
  }

  createForm() {
    this.form = this.fb.group({
      // @ts-ignore
      title: [this.course.title, Validators.required],
      // @ts-ignore
      visible: [this.course.visible, Validators.required],
      // @ts-ignore
      language: [this.course.language, Validators.required],
      // @ts-ignore
      category: [this.course.category, Validators.required],
      // @ts-ignore
      campusList: [this.course.campusList],
      // @ts-ignore
      formula: [this.course.formula, Validators.required],
      // @ts-ignore
      priority: [this.course.priority ? this.course.priority : '1', Validators.required]
    });
  }

  updateCampus(campusList: any[]) {
    const list = [];
    for(let c of campusList) {
      list[c.name.toLowerCase()] = true;
    }
    console.log(list);
    
    this.form.controls['campusList'].setValue(list);

    console.log(this.form.controls['campusList'].value);
    
    
  }

  remove() {
    if (confirm('Are you sure you want to delete this course=')) {
      this.courseManager.remove(this.id).then(() => {
        this.router.navigate(['/admin']);
      });
    }
  }

  onSubmit() {
    const tmpData = this.form.getRawValue();
    
    tmpData.priority = +tmpData.priority;
    tmpData.date =  new Date(this.dateModel.year + '-' + this.dateModel.month + '-' + this.dateModel.day);
    const editor = document.querySelector('trix-editor');
    // @ts-ignore
    tmpData.description = editor.value;

    const campusMap = {}

    for(let c in tmpData.campusList) {
      campusMap[c] = true;
    }
    console.log(campusMap);

    tmpData.campusList = campusMap;
    
    this.courseManager.update(tmpData, this.id)
      .then(() => {
        this.router.navigate(['/admin']);
      });
  }
}
