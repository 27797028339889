import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CourseCategoryService {
  categories = [
    {value: 'mba', name: 'MBA - Master in Business & Administration'},
    {value: 'mba-global-management-leadership', name: 'MBA - Global Management & Leadership'},
    {value: 'mba-finance', name: 'MBA - Finance'},
    {value: 'mba-entrepreneurship', name: 'MBA - Entrepreneurship'},
    {value: 'mba-human-resources', name: 'MBA - Human Resources'},
    {value: 'mba-fintech', name: 'MBA - Financial Technology'},
    {value: 'mba-health-safety-environment', name: 'MBA - Health, Safety and Environment'},
    {value: 'mba-luxury-management', name: 'MBA - Luxury Management'},
    {value: 'mba-marketing', name: 'MBA - Marketing'},
    {value: 'mba-no-profit-organization', name: 'MBA - Non for Profit Organizations'},
    {value: 'mba-project-management', name: 'MBA - Project Management'},
    {value: 'mba-logistics', name: 'MBA - Logistics Management'},
    {value: 'bba', name: 'BBA - Bachelor Degree in Business & Administration'},
    {value: 'dba', name: 'DBA - Doctorate of Business Administration'},
  ];

  constructor() {
  }

  makeItNice(str: string) {
    return str.replace('-', ' - ').replace(
        /\w\S*/g,
        (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
  }
}
